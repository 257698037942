import { Button as ChakraButton } from "@chakra-ui/react";
import { forwardRef } from "@chakra-ui/system";

import { IProps } from "./types";

export const Button = forwardRef<IProps, "button">((props, btnRef) => {
  const { children, testingContext, testingContextId, ...otherProps } = props;
  return (
    <ChakraButton
      variant="base"
      size="md"
      data-test={testingContext}
      data-test-id={testingContextId}
      ref={btnRef}
      {...otherProps}
    >
      {children}
    </ChakraButton>
  );
});
